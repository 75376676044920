<div class="content-wrapper container-xxl p-0">
    <div class="content-body p-4">
        <div class="d-flex justify-content-center text-center">
            <div class="card col-6">
                <div class="card-body" *ngIf="parameters">
                    <div class="dummy-positioning">
                        <div class="success-icon">
                            <div class="success-icon__tip"></div>
                            <div class="success-icon__long"></div>
                        </div>
                    </div>
                    <div [innerHTML]="parameters.text"></div>
                    <div *ngIf="order && order.dealer">
                        <div [innerHTML]="parameters.dealer_text"></div>
                        <h3 class="text-danger">{{order.dealer.promoCode}}: - {{order.dealer.percentage}} %</h3>
                    </div>
                    <a href="{{parameters.link}}" class="btn btn-primary mt-2">Accueil</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- contact us -->
<app-contact></app-contact>
<!--/ contact us -->