import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  private _refreshDiscount = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshDiscount() {
    return this._refreshDiscount;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/discount`);
  }

  create(title, type, percentage, maximum, minimum) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/create/discount`, { title, type, percentage, maximum, minimum } )
      .pipe(
        tap(() => {
          this._refreshDiscount.next()
        })
      );
  }

  update(id, title, type, percentage, maximum, minimum) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/update/discount/${id}`, { title, type, percentage, maximum, minimum })
      .pipe(
        tap(() => {
          this._refreshDiscount.next()
        })
      );
  }

  updateStatut(id, statut) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/statut/discount/${id}`, { statut } )
      .pipe(
        tap(() => {
          this._refreshDiscount.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/discount/${id}`)
      .pipe(
        tap(() => {
          this._refreshDiscount.next()
        })
      );
  }

}
