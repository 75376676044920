import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {

  private _refreshAgency = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshAgency() {
    return this._refreshAgency;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/agency`);
  }
  getAvailableAgencies() {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/agency_by_statut`);
  }
  getAgency(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/agency/${id}`);
  }

  create(title, address, phone, statut, logo, firstName, lastName, email, password) {
    const formData: FormData = new FormData();
    formData.append('title', title);
    formData.append('address', address);
    formData.append('phone', phone);
    formData.append('statut', statut);
    formData.append('logo', logo);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('password', password);

    return this._httpClient
      .post(`${environment.apiUrl}/api/create/agency`, formData)
      .pipe(
        tap(() => {
          this._refreshAgency.next()
        })
      );
  }

  update(id, title, address, phone, logo, firstName, lastName, email, password) {
    const formData: FormData = new FormData();
    formData.append('title', title);
    formData.append('address', address);
    formData.append('phone', phone);
    formData.append('logo', logo);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('password', password);

    return this._httpClient
      .post(`${environment.apiUrl}/api/update/agency/${id}`, formData)
      .pipe(
        tap(() => {
          this._refreshAgency.next()
        })
      );
  }

  updateProfile(id, title, address, phone, logo, firstName, lastName, email) {
    const formData: FormData = new FormData();
    formData.append('title', title);
    formData.append('address', address);
    formData.append('phone', phone);
    formData.append('logo', logo);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);

    return this._httpClient
      .post(`${environment.apiUrl}/api/update/agency/${id}`, formData)
      .pipe(
        tap(() => {
          this._refreshAgency.next()
        })
      );
  }

  updateStatut(id, statut) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/statut/agency/${id}`, { statut })
      .pipe(
        tap(() => {
          this._refreshAgency.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/agency/${id}`)
      .pipe(
        tap(() => {
          this._refreshAgency.next()
        })
      );
  }

}
