import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { BannerService } from 'app/Service/banner/banner.service';
import { environment } from 'environments/environment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderComponent implements OnInit {
  apiUrl = environment.apiUrl

  public banners

  public swiperAutoplay: SwiperConfigInterface = {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  };

  constructor(private _coreConfigService: CoreConfigService, private bannerService: BannerService) {
    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: false
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  getBanners() {
    this.bannerService.getBannerBy('reservation')
      .subscribe({
        next: (data) => {
          this.banners = data;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.getBanners()
  }

}
