import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {

  private _refreshParameter = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshParameter() {
    return this._refreshParameter;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/parameter`);
  }

  getInvoiceParammeter() {
    return this._httpClient.get(`${environment.apiUrl}/api/invoice/parameter`);
  }

  getOrderSuccessParammeter() {
    return this._httpClient.get(`${environment.apiUrl}/api/order_success/parameter`);
  }

  getSpecificParameter(code) {
    return this._httpClient.get(`${environment.apiUrl}/api/specific/parameter/${code}`);
  }

  create(title, code, value) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/create/parameter`, { title, code, value })
      .pipe(
        tap(() => {
          this._refreshParameter.next()
        })
      );
  }

  update(id, title, value) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/update/parameter/${id}`, { title, value })
      .pipe(
        tap(() => {
          this._refreshParameter.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/parameter/${id}`)
      .pipe(
        tap(() => {
          this._refreshParameter.next()
        })
      );
  }

  static getAppTitle() {
    // this.getSpecificParameter('app_title')
    // .subscribe(
    //   response => {
    //     return response
    //   },
    //   error => {
    //     console.log(error);
    //   });
  }

}
