import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule } from '@core/components';
import { ContentHeaderModule } from './layout/components/content-header/content-header.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatePickerI18nModule } from './main/forms/form-elements/date-time-picker/date-picker-i18n/date-picker-i18n.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ComponentModule } from './Component/component.module';
import { PreviewComponent } from './Component/Agency/order/invoice/preview/preview.component';
import { OrderComponent } from './Component/Customer/order/order.component';
import { EventComponent } from './Component/Customer/event/event.component';
import { OrderSuccessComponent } from './Component/Customer/order-success/order-success.component';
import { CircuitComponent } from './Component/Customer/circuit/circuit.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'component/agency/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'order',
    component: OrderComponent,
  },
  {
    path: 'order/:id',
    component: EventComponent,
  },
  {
    path: 'order/circuit/:id',
    component: CircuitComponent,
  },
  {
    path: 'invoice/:id',
    component: PreviewComponent,
  },
  {
    path: 'success/:token',
    component: OrderSuccessComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CoreCommonModule,
    CoreSidebarModule,
    ContentHeaderModule,
    NgSelectModule,
    DatePickerI18nModule,
    SweetAlert2Module.forRoot(),
    ComponentModule,
  ],
  exports: [RouterModule],
  providers: [DatePipe],
})
export class AppRoutingModule { }
