<div class="content-wrapper container-xxl p-0">
    <div class="content-body pt-4 pl-4 pr-4 pb-2">
        <div class="card">
            
            <!-- autoplay swiper start -->
            <section id="component-swiper-autoplay">
                <swiper class="swiper-autoplay swiper-container" [config]="swiperAutoplay">
                    <img *ngFor="let banner of banners" src="{{apiUrl}}/uploads/{{banner.image}}" alt="banner" />
                </swiper>
            </section>
            <!-- autoplay swiper ends -->

            <app-add-order></app-add-order>

        </div>
        <!-- contact us -->
        <app-contact></app-contact>
        <!--/ contact us -->
    </div>
</div>