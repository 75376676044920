import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CircuitService {

  private _refreshCircuit = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshCircuit() {
    return this._refreshCircuit;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/circuit`);
  }

  getActiveCircuit() {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/active_circuit`);
  }

  getCircuit(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/circuit/${id}`);
  }
  getCircuitByAlias(alias) {
    return this._httpClient.get(`${environment.apiUrl}/api/circuitByAlias/${alias}`);
  }

  create(title, short_description, description, difficulty, distance, duration, priceWithBike, priceWithoutBike, banner, image, images, map, sites) {
    const formData: FormData = new FormData();
    formData.append('title', title); formData.append('short_description', short_description);
    formData.append('description', description);
    formData.append('difficulty', difficulty); formData.append('distance', distance);
    formData.append('duration', duration); formData.append('map', map);
    formData.append('priceWithBike', priceWithBike); formData.append('priceWithoutBike', priceWithoutBike);

    formData.append('banner', banner);
    formData.append('image', image);
    if (images != null) {
      for (let i = 0; i < images.length; i++) {
        formData.append('images[]', images[i]);
      }
    }
    if (sites != null) {
      for (let i = 0; i < sites.length; i++) {
        formData.append('sites[]', sites[i]);
      }
    }

    return this._httpClient
      .post(`${environment.apiUrl}/api/create/circuit`, formData)
      .pipe(
        tap(() => {
          this._refreshCircuit.next()
        })
      );
  }

  update(id, title, short_description, description, difficulty, distance, duration, priceWithBike, priceWithoutBike, banner, image, images, map, sites) {
    const formData: FormData = new FormData();
    formData.append('title', title); formData.append('short_description', short_description);
    formData.append('description', description);
    formData.append('difficulty', difficulty); formData.append('distance', distance);
    formData.append('duration', duration); formData.append('map', map);
    formData.append('priceWithBike', priceWithBike); formData.append('priceWithoutBike', priceWithoutBike);

    formData.append('banner', banner);
    formData.append('image', image);
    if (images != null) {
      for (let i = 0; i < images.length; i++) {
        formData.append('images[]', images[i]);
      }
    }
    if (sites != null) {
      for (let i = 0; i < sites.length; i++) {
        formData.append('sites[]', sites[i]);
      }
    }
    return this._httpClient
      .post(`${environment.apiUrl}/api/update/circuit/${id}`, formData)
      .pipe(
        tap(() => {
          this._refreshCircuit.next()
        })
      );
  }

  updateStatut(id, statut) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/statut/circuit/${id}`, { statut })
      .pipe(
        tap(() => {
          this._refreshCircuit.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/circuit/${id}`)
      .pipe(
        tap(() => {
          this._refreshCircuit.next()
        })
      );
  }

}
