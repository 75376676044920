<form [formGroup]="orderForm" class="form form-horizontal ml-2 mr-2">
    <div class="card" *ngIf="event">
        <img src="{{apiUrl}}/uploads/{{event.banner}}">
        <div class="card-header row">
            <div class="row justify-content-center text-center col-lg-4 col-md-6 "><img
                    src="{{apiUrl}}/uploads/{{event.principal_image}}" style="max-width: 100%;">
                <h3 style="color: #14226e;"> Organisé par </h3>
                <div class="col-12 card-input p-50 ng-tns-c260-2"><img height="80" class="ng-tns-c260-2"
                        src="{{apiUrl}}/uploads/{{event.agency.logo}}">
                    <h4 class="ng-tns-c260-2">{{event.agency.title}}</h4>
                    <h6 class="ng-tns-c260-2">{{event.agency.address}}</h6>
                    <h6 class="ng-tns-c260-2">{{event.agency.phone}}</h6><small
                        class="ng-tns-c260-2">{{event.agency.bikesNumber}}</small>
                </div>
            </div>
            <div class="text-right col-8">
                <h1 style="color: #14226e;">{{event.title}} </h1>
                <h4 class="alert-body">
                    De <strong>{{ event.start|date: 'yyyy-MM-dd HH:mm' }}</strong>
                    à <strong>{{ event.end|date: 'yyyy-MM-dd HH:mm' }}</strong>
                </h4>
                <h3>{{event.description}}</h3>
            </div>
        </div>
        <div *ngIf="step == 2 || step == 3">
            <div class="col-12">
                <!-- tariffs -->
                <div *ngIf="event">
                    <ngb-alert [type]="'primary'" [dismissible]="false">
                        <div class="alert-body">
                            Prix <strong>avec</strong> location vélo <strong>{{event.priceWithBike}} DT</strong>
                        </div>
                    </ngb-alert>
                    <ngb-alert [type]="'primary'" [dismissible]="false">
                        <div class="alert-body">
                            Prix <strong>sans</strong> location vélo (J'ai mon vélo) <strong>{{event.priceWithoutBike}}
                                DT</strong>
                        </div>
                    </ngb-alert>
                </div>
                <!--/ tariffs -->
                <!-- participants -->
                <div *ngFor="let form of participantsFormHolder; let i = index">
                    <form [formGroup]="form">
                        <div class="divider divider-left">
                            <div class="divider-text"><small class="text-muted">Participant {{i + 1}}</small></div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-12" *ngIf="!currentUser || currentUser.role != 'agency'">
                                <div class="form-group">
                                    <label>Cin / Passport</label>
                                    <input type="text" class="form-control" formControlName="participantCin"
                                        (change)="handleParticipantCin()" />
                                </div>
                            </div>
                            <div class="col-md-3 col-12" *ngIf="currentUser && currentUser.role === 'agency'">
                                <div class="form-group">
                                    <label>Cin / Passport</label>
                                    <input type="text" class="form-control" formControlName="participantCin"
                                        list="dynamicCin" (keyup)="getParticipant($event, i, 'participantCin')"
                                        (change)="handleParticipantCin()" />
                                    <datalist id="dynamicCin">
                                        <option *ngFor="let item of oldParticipants" [value]="item.participantCin">
                                            {{item.participantCin}}
                                        </option>
                                    </datalist>
                                </div>
                            </div>
                            <div class="col-md-3 col-12">
                                <div class="form-group">
                                    <label>Prénom</label>
                                    <input type="text" class="form-control" formControlName="participantFirstName" />
                                </div>
                            </div>
                            <div class="col-md-3 col-12">
                                <label>Nom</label>
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="participantLastName" />
                                </div>
                            </div>
                            <div class="col-md-3 col-12">
                                <label>Téléphone</label>
                                <div class="form-group">
                                    <input type="number" formControlName="participantPhone" min="10000000"
                                        class="form-control" />
                                    <small
                                        *ngIf="form.get('participantPhone').touched && form.get('participantPhone').invalid"
                                        class="text-danger fs-6">
                                        Le téléphone doit comporter 8 caractères!
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-3 col-12">
                                <label>E-mail</label>
                                <div class="form-group">
                                    <input type="email" class="form-control" formControlName="participantEmail"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
                                    <small
                                        *ngIf="form.get('participantEmail').touched && form.get('participantEmail').value != '' && form.get('participantEmail').invalid"
                                        class="text-danger fs-6">
                                        L'email doit être valide!
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="form-group">
                                    <label>Vélo</label>
                                    <div class="text-center" *ngIf="!bikes">
                                        <span class="spinner-border spinner-border-sm"></span>
                                    </div>
                                    <ng-select *ngIf="bikes" formControlName="participantBike"
                                        [notFoundText]="'Aucun vélo disponible'" (clear)="clearBike(form)"
                                        (change)="calculatePrice()">
                                        <ng-option *ngFor="let bike of bikes" [value]="bike"
                                            [disabled]="chosenBikes.includes(bike)">
                                            <img src="{{apiUrl}}/uploads/{{bike.principal_image}}" width="40">
                                            {{bike.reference}} {{bike.category_title}} {{bike.kind}} -
                                            {{bike.size}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <button class="btn btn-danger btn-sm" type="button" (click)="clearBike(form)"
                                    rippleEffect>Sans location vélo (J'ai mon vélo)</button>
                            </div>
                            <div class="col-md-3 col-12"
                                *ngIf="form.get('participantBike').dirty && form.get('participantBike').value != ''">
                                <img src="{{apiUrl}}/uploads/{{form.get('participantBike').value['principal_image']}}"
                                    width="200">
                            </div>
                            <div class="custom-control custom-radio m-1">
                                <input type="radio" name="responsible" formControleName="responsible"
                                    id="responsible{{i}}" class="custom-control-input" (change)="setIsResonsible(i)"
                                    [checked]="i == responsibleIndex" [value]="test" />
                                <label class="custom-control-label" for="responsible{{i}}">Responsable</label>
                            </div>
                            <div class="form-group" *ngIf="i != 0">
                                <button class="btn btn-icon btn-danger" (click)="deleteParticipant(form)" rippleEffect>
                                    <i data-feather="x"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <button class="btn btn-icon mr-1" style="background-color: #e4c911" type="button"
                    (click)="addParticipant()" rippleEffect>
                    <i data-feather="plus"></i> Ajouter un participant
                </button>
                <!-- /participants -->
                <div class="text-right" *ngIf="bikes">
                    <h4 class="text-primary" *ngIf="discountedPrice === 0">
                        Prix total : {{totalPrice.toFixed(2)}} Dt
                    </h4>
                    <h4 class="text-primary" *ngIf="discountedPrice != 0">
                        Prix total : {{discountedPrice.toFixed(2)}} Dt
                    </h4>
                </div>
            </div>
            <div class="card-footer text-right" *ngIf="step == 2">
                <button *ngIf="currentUser && currentUser.role === 'agency'" type="submit" class="btn btn-primary"
                    [disabled]="loadingSubmitOrder" (click)="next()">
                    Préciser le prix
                </button>
                <button *ngIf="!currentUser || currentUser.role != 'agency'" type="submit" class="btn btn-primary"
                    [disabled]="loadingSubmitOrder" (click)="submitParticipants()">
                    <span *ngIf="loadingSubmitOrder" class="spinner-border spinner-border-sm mr-1"></span>
                    Résever
                </button>
            </div>
        </div>
        <div *ngIf="step == 3">
            <div class="col-12">
                <div class="form-group row">
                    <div class="col-sm-3 col-form-label">
                        <label>Accessoires</label>
                    </div>
                    <div class="col-sm-6">
                        <ng-select placeholder="Accessoires" formControlName="accessory" multiple="true">
                            <ng-option *ngFor="let accessory of accessories" [value]="accessory.id">
                                {{accessory.reference}} - {{accessory.title}}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="currentUser && currentUser.role === 'agency'">
                <div class="form-group row">
                    <div class="col-sm-3 col-form-label">
                        <label>Réduction</label>
                    </div>
                    <div class="col-sm-6">
                        <ng-select formControlName="discount" placeholder="Réduction" (clear)="clearDiscount()"
                            (change)="handleDiscount($event)">
                            <ng-option *ngFor="let discount of discounts" [value]="discount">
                                {{discount.title}}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="currentUser && currentUser.role === 'agency'">
                <div class="form-group row">
                    <div class="col-sm-3 col-form-label">
                        <label>Prix agent</label>
                    </div>
                    <div class="col-sm-6">
                        <input type="number" class="form-control" formControlName="agent_price"
                            placeholder="Prix agent" />
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group row">
                    <div class="col-sm-3 col-form-label">
                        <label>Commentaire</label>
                    </div>
                    <div class="col-sm-6">
                        <textarea class="form-control" formControlName="comment" placeholder="Commentaire"></textarea>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <h6 *ngIf="cuationVelo && chosenBikes.length > 0">Caution vélos : {{ cuationVelo.value *
                    chosenBikes.length }} Dt</h6>
                <h6
                    *ngIf="cautionAccessoire && orderForm.get('accessory').touched && orderForm.get('accessory').value.length != 0">
                    Caution Accessoires :
                    {{ cautionAccessoire.value * orderForm.get('accessory').value.length }} Dt
                </h6>
            </div>
            <div class="card-footer text-right">
                <button type="submit" [disabled]="loadingSubmitOrder" (click)="submitParticipants()"
                    class="btn btn-primary">
                    <span *ngIf="loadingSubmitOrder" class="spinner-border spinner-border-sm mr-1"></span>
                    Ajouter
                </button>
            </div>
        </div>
    </div>
</form>

<core-sidebar name="bike-sidebar" class="customizer d-none d-md-block">
    <a class="customizer-toggle d-flex align-items-center justify-content-center" *ngIf="bikes"
        (click)="toggleSidebar('bike-sidebar')">
        <span [data-feather]="'plus-circle'" [class]="'spinner'" [size]="20"></span>
    </a>
    <app-bike-sidebar [bikes]="bikes"></app-bike-sidebar>
</core-sidebar>