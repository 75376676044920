import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  // Dashboard
  {
    id: 'apps',
    type: 'section',
    title: 'Tableau de bord',
    icon: 'package',
    children: [
      {
        id: 'calendar',
        title: 'Calendrier',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['admin', 'agency'], //? To set multiple role: ['Admin', 'Client']
        icon: 'calendar',
        url: 'component/agency/dashboard'
      },
      {
        id: 'agency',
        title: 'Agence',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'users',
        url: 'component/agency/agencies'
      },
      {
        id: 'dealer',
        title: 'Revendeur',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'corner-right-down',
        url: 'component/agency/dealer'
      },
    ]
  },
  {
    id: 'apps',
    type: 'section',
    title: 'Vélos & Accessoires',
    icon: 'package',
    children: [
      {
        id: 'bike',
        title: 'Vélo',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        //role: ['admin', 'agency'], //? To set multiple role: ['Admin', 'Client']
        icon: 'plus-circle',
        url: 'component/agency/bike'
      },
      {
        id: 'accessory',
        title: 'Accessoire',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        //role: ['admin', 'agency'], //? To set multiple role: ['Admin', 'Client']
        icon: 'plus-square',
        url: 'component/agency/accessory'
      },
      {
        id: 'brand',
        title: 'Marque',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ["admin"], //? To set multiple role: ['Admin', 'Client']
        icon: 'tag',
        url: 'component/agency/brand'
      },
      {
        id: 'category',
        title: 'Classe',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'grid',
        url: 'component/agency/category'
      },
      {
        id: 'category-accessory',
        title: 'Catégorie accessoire',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'grid',
        url: 'component/agency/category-accessory'
      },
      {
        id: 'type',
        title: 'Type',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'type',
        url: 'component/agency/kind'
      },
      {
        id: 'size',
        title: 'Taille',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'maximize-2',
        url: 'component/agency/size'
      },
      {
        id: 'tariff',
        title: 'Tarif',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'dollar-sign',
        url: 'component/agency/tariff'
      },
    ]
  },
  {
    id: 'apps',
    type: 'section',
    title: 'Commande',
    icon: 'package',
    children: [
      {
        id: 'period',
        title: 'Période',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'watch',
        url: 'component/agency/period'
      },
      {
        id: 'discount',
        title: 'Réduction',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'percent',
        url: 'component/agency/discount'
      },
      {
        id: 'event',
        title: 'Événement',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        //role: ['admin', 'agency'], //? To set multiple role: ['Admin', 'Client']
        icon: 'calendar',
        url: 'component/agency/event'
      },
      {
        id: 'circuit_agency',
        title: 'Circuit',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['agency'], //? To set multiple role: ['Admin', 'Client']
        icon: 'map',
        url: 'component/agency/circuit'
      },
      {
        id: 'order',
        title: 'Commande',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        //role: ['admin', 'agency'], //? To set multiple role: ['Admin', 'Client']
        icon: 'shopping-cart',
        url: 'component/agency/order'
      },
      {
        id: 'order_report',
        title: 'Rapport',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        //role: ['admin', 'agency'], //? To set multiple role: ['Admin', 'Client']
        icon: 'file-text',
        url: 'component/agency/order_report'
      },
      {
        id: 'participant',
        title: 'Participant',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        //role: ['admin', 'agency'], //? To set multiple role: ['Admin', 'Client']
        icon: 'users',
        url: 'component/agency/participant'
      },
    ]
  },
  {
    id: 'paramter',
    title: 'Paramètre',
    //translate: 'MENU.DASHBOARD.ANALYTICS',
    type: 'item',
    role: ['admin'], //? To set multiple role: ['Admin', 'Client']
    icon: 'sliders',
    url: 'component/agency/parameter'
  },
  {
    id: 'banner',
    title: 'Bannière',
    //translate: 'MENU.DASHBOARD.ANALYTICS',
    type: 'item',
    role: ['admin'], //? To set multiple role: ['Admin', 'Client']
    icon: 'bold',
    url: 'component/agency/banner'
  },
  {
    id: 'apps',
    type: 'section',
    title: 'Circuit',
    icon: 'package',
    role: ['admin'],
    children: [
      {
        id: 'siteCategory',
        title: 'Catégorie Site',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'grid',
        url: 'component/agency/site_category'
      },
      {
        id: 'site',
        title: 'Site',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'map-pin',
        url: 'component/agency/site'
      },
      {
        id: 'circuit',
        title: 'Circuit',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'map',
        url: 'component/agency/circuit'
      },
    ]
  },
];
