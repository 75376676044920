import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _refreshUser = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshUser() {
    return this._refreshUser;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/user`);
  }
  getUser(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/user/${id}`);
  }
  getAdmin() {
    return this._httpClient.get(`${environment.apiUrl}/api/admin`);
  }

  create(logo, firstName, lastName, email, password) {
    const formData: FormData = new FormData();
    formData.append('logo', logo);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('password', password);

    return this._httpClient
      .post(`${environment.apiUrl}/api/create/user`, formData)
      .pipe(
        tap(() => {
          this._refreshUser.next()
        })
      );
  }

  update(id, logo, firstName, lastName, email) {
    const formData: FormData = new FormData();
    formData.append('logo', logo);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);

    return this._httpClient
      .post(`${environment.apiUrl}/api/update/user/${id}`, formData)
      .pipe(
        tap(() => {
          this._refreshUser.next()
        })
      );
  }

  updatePassword(id, password) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/update_password/user/${id}`, { password })
      .pipe(
        tap(() => {
          this._refreshUser.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/user/${id}`)
      .pipe(
        tap(() => {
          this._refreshUser.next()
        })
      );
  }

}
