import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { environment } from 'environments/environment';

import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from 'app/auth/service';
import { CoreConfigService } from '@core/services/config.service';
import { ParticipantService } from 'app/Service/participant/participant.service';
import { BikeService } from 'app/Service/bike/bike.service';
import { AccessoryService } from 'app/Service/accessory/accessory.service';
import { EventService } from 'app/Service/event/event.service';
import { ParameterService } from 'app/Service/parameter/parameter.service';
import { DiscountService } from 'app/Service/discount/discount.service';
import { AlertService } from 'app/Service/alert/alert.service';
import { OrderParticipantBikeService } from 'app/Service/order-participant-bike/order-participant-bike.service';
import { OrderService } from 'app/Service/order/order.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

@Component({
  selector: 'app-order-event',
  templateUrl: './order-event.component.html',
  styleUrls: ['./order-event.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderEventComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  apiUrl = environment.apiUrl

  public step = 1

  public event
  public oldParticipants
  public bikes
  public chosenBikes = []
  public accessories
  public discounts
  public totalPrice = 0
  public discountedPrice = 0

  public cautionAccessoire
  public cuationVelo

  public responsibleIndex = 0
  public responsible = null

  participantsFormHolder = [this._formBuilder.group({
    participantId: new FormControl(''),
    participantFirstName: new FormControl(''), participantLastName: new FormControl(''),
    participantCin: new FormControl(''), participantPhone: new FormControl(''),
    participantEmail: new FormControl(''),
    participantBike: new FormControl(''), bikePrice: '',
    isResponsible: new FormControl(true)
  })];

  public orderForm: FormGroup;
  public orderSubmitted = false
  public loadingSubmitOrder = false

  public createdOrderId

  constructor(
    private authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private eventService: EventService,
    private participantService: ParticipantService,
    private bikeService: BikeService,
    private accessoryService: AccessoryService,
    private discountService: DiscountService,
    private parameterService: ParameterService,
    private orderService: OrderService,
    private orderParticipantBike: OrderParticipantBikeService,
    private _formBuilder: FormBuilder,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private _router: Router
  ) {
    if (!this.currentUser || this.currentUser.role != 'agency') {
      // Configure the layout
      this._coreConfigService.config = {
        layout: {
          navbar: {
            hidden: true
          },
          menu: {
            hidden: true
          },
          footer: {
            hidden: true
          },
          customizer: false,
          enableLocalStorage: false
        }
      };
    }
  }
  // convenience getter for easy access to order form fields
  get o() {
    return this.orderForm.controls;
  }

  createOrderParticipantBike() {
    for (let p = 0; p < this.participantsFormHolder.length; p++) {
      this.orderParticipantBike.create(this.createdOrderId['id'],
        this.participantsFormHolder[p].get('participantId').value,
        this.participantsFormHolder[p].get('participantBike').value['id'],
        this.participantsFormHolder[p]['bikePrice'])
        .subscribe({
          next: (data) => {
            if (p + 1 == this.participantsFormHolder.length) {
              this.loadingSubmitOrder = false
              if (this.currentUser && this.currentUser.role === 'agency') {
                if (this.event) {
                  this._router.navigate([`/component/agency/event/detail/${this.event.id}`]);
                } else {
                  this._router.navigate([`/component/agency/order`]);
                }
              } else {
                this._router.navigate([`/success/${this.createdOrderId['token']}`]);
              }
            }
          },
          error: (e) => {
            this.loadingSubmitOrder = false
            console.error(e)
          }
        });
    }
  }
  submitOrder() {
    let price = this.totalPrice
    let discount = ''
    if (this.o.discount.touched && this.o.discount.value != '') {
      discount = this.o.discount.value.id
      price = this.discountedPrice
    }
    this.responsible = this.participantsFormHolder.find(item => item.get('isResponsible').value == true)
    let user = null
    if (this.currentUser) {
      user = this.currentUser.id
    }
    this.chosenBikes = this.participantsFormHolder.filter(item => item.get('participantBike').value != null && item.get('participantBike').value != '')
    if (this.chosenBikes.length > 0) {
      this.chosenBikes = this.chosenBikes.map(item => item.get('participantBike').value['id'])
    }
    this.orderService.create(user, this.event.agency.id, null, discount, this.responsible.get('participantId').value, this.participantsFormHolder[0].get('participantId').value, this.event.start, this.event.end, this.o.comment.value, price, this.o.agent_price.value, this.o.accessory.value, this.chosenBikes, this.event.id, null, null)
      .subscribe({
        next: (data) => {
          this.createdOrderId = data
          this.createOrderParticipantBike()
        },
        error: (e) => {
          this.loadingSubmitOrder = false
          console.error(e)
        }
      });
  }
  createParticipants(newOnes) {
    for (let p = 0; p < newOnes.length; p++) {
      this.participantService.create(newOnes[p].get('participantCin').value,
        newOnes[p].get('participantFirstName').value, newOnes[p].get('participantLastName').value,
        newOnes[p].get('participantPhone').value, newOnes[p].get('participantEmail').value)
        .subscribe({
          next: (participant_id) => {
            newOnes[p].get('participantId').setValue(participant_id)
            if (p + 1 == newOnes.length) {
              this.submitOrder()
            }
          },
          error: (e) => {
            this.loadingSubmitOrder = false
            console.error(e)
          }
        });
    }
  }

  submitParticipants() {
    if (this.checkParticipantsForm() == true) {
      this.alertService.errorAlert(
        'Vous devez indiquer la cin, le prénom, le nom et le numéro de téléphone de chaque participant',
        'veuillez entrer des données appropriées');
    } else {
      let participantsCin = this.participantsFormHolder.map(item => item.get('participantCin').value)
      if (participantsCin.some((val, i) => participantsCin.indexOf(val) !== i)) {
        this.alertService.errorAlert('Vous avez des participants en double', 'Veuillez vérifier');
      } else {
        this.loadingSubmitOrder = true
        let newOnes = this.participantsFormHolder.filter(f => f.get('participantId').value === '')
        if (newOnes.length != 0) {
          this.createParticipants(newOnes)
        } else {
          this.submitOrder()
        }
      }
    }
  }

  clearDiscount() {
    this.discountedPrice = 0
    this.orderForm.get("agent_price").setValue(this.totalPrice.toFixed(2));
  }
  handleDiscount(e) {
    if (e && e.percentage != null) {
      this.discountedPrice = this.totalPrice - ((e.percentage / 100) * this.totalPrice)
      this.orderForm.get("agent_price").setValue(this.discountedPrice.toFixed(2));
    }
  }
  clearBike(form) {
    form.get('participantBike').setValue('')
    this.calculatePrice()
  }

  next() {
    switch (this.step) {
      case 1: {
        this.getBikes()
        break;
      }
      case 2: {
        this.getAccessories()
        this.getDiscounts()
        this.getBikeDepositParameter()
        this.getAccessoryDepositParameter()
        break;
      }
    }
    this.step++
  }

  getAccessoryDepositParameter() {
    this.parameterService.getSpecificParameter('accessory_deposit')
      .subscribe({
        next: (data) => {
          this.cautionAccessoire = data[0]
        },
        error: (e) => console.error(e)
      });
  }
  getBikeDepositParameter() {
    this.parameterService.getSpecificParameter('bike_deposit')
      .subscribe({
        next: (data) => {
          this.cuationVelo = data[0];
        },
        error: (e) => console.error(e)
      });
  }
  getDiscounts() {
    this.discountService.getAll()
      .subscribe({
        next: (data) => {
          this.discounts = data
          this.discounts = this.discounts.filter((obj) => { return obj.type === 'promotion' })
        },
        error: (e) => console.error(e)
      });
  }
  getAccessories(): void {
    if (this.currentUser && this.currentUser.role === 'agency') {
      this.accessoryService.getAll()
        .subscribe({
          next: (data) => {
            this.accessories = data;
          },
          error: (e) => console.error(e)
        });
    }
  }
  getBikes(): void {
    this.bikeService.getFreeBikes(this.event.agency.id, this.event.start, this.event.end)
      .subscribe({
        next: (data) => {
          this.bikes = data;
          this.toggleSidebar('bike-sidebar')
        },
        error: (e) => console.error(e)
      });
  }
  getOldParticipants(): void {
    this.participantService.getAll()
      .subscribe({
        next: (data) => {
          this.oldParticipants = data;
        },
        error: (e) => console.error(e)
      });
  }
  getEventData(alias) {
    this.eventService.getEventByAlias(alias)
      .subscribe({
        next: (data) => {
          this.event = data;
          if (this.currentUser && this.currentUser.role === 'agency') {
            this.getOldParticipants()
          }
          this.next()
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.getEventData(this.route.snapshot.paramMap.get('id'))

    //order
    this.orderForm = this._formBuilder.group({
      comment: [''],
      accessory: [''],
      responsible: ['', [Validators.required]],
      agent_price: [''],
      discount: [''],
    });
  }

  //participants 
  addParticipant() {
    let newForm = {
      participantId: new FormControl(''),
      participantFirstName: new FormControl(''), participantLastName: new FormControl(''),
      participantCin: new FormControl(''), participantPhone: new FormControl(''),
      participantEmail: new FormControl(''),
      participantBike: new FormControl(''), bikePrice: '',
      isResponsible: new FormControl(false)
    }
    this.participantsFormHolder.push(this._formBuilder.group(newForm))
  }
  deleteParticipant(toDelete) {
    if (toDelete.get('isResponsible').value == true) {
      this.responsibleIndex = 0
      this.participantsFormHolder[0].get('isResponsible').setValue(true)
    }
    let aux: any[] = [];
    for (let form of this.participantsFormHolder) {
      if (toDelete !== form) {
        aux.push(form);
      }
    }
    this.participantsFormHolder = aux;
    this.calculatePrice()
  }
  setIsResonsible(index) {
    this.responsibleIndex = index
    this.participantsFormHolder.forEach(function (item) {
      item.get('isResponsible').setValue(false)
    });
    this.participantsFormHolder[index].get('isResponsible').setValue(true)
  }
  handleParticipantCin() {
    this.calculatePrice()
  }
  calculatePrice() {
    this.chosenBikes = this.participantsFormHolder.filter(item => item.get('participantBike').value && item.get('participantBike').value != '').map(item => item.get('participantBike').value)
    this.totalPrice = ((this.participantsFormHolder.length - this.chosenBikes.map(item => item != null).length) * this.event.priceWithoutBike) + this.chosenBikes.length * this.event.priceWithBike
    this.orderForm.get("agent_price").setValue(this.totalPrice.toFixed(2));
  }
  checkParticipantsForm() {
    let response = 0
    this.participantsFormHolder.forEach(element => {
      if (element.get('participantId').value != '') {
        response++
      } else {
        if (element.get('participantFirstName').value != '' && element.get('participantFirstName').value != null &&
          element.get('participantLastName').value != '' && element.get('participantLastName').value != null &&
          element.get('participantCin').value != '' && element.get('participantCin').value != null &&
          element.get('participantPhone').value != '' && element.get('participantPhone').value != null && element.get('participantPhone').valid
          && element.get('participantEmail').valid) {
          response++
        }
      }
    });
    if (response == this.participantsFormHolder.length) {
      return false
    } else {
      return true
    }
  }

  getParticipant(event, formIndex, attribut) {
    let currentParticipant = this.oldParticipants.find((obj) => {
      return obj[attribut] == event.target.value
    })
    if (currentParticipant) {
      this.participantsFormHolder[formIndex].patchValue({
        participantId: currentParticipant.participantId,
        participantFirstName: currentParticipant.participantFirstName,
        participantLastName: currentParticipant.participantLastName,
        participantCin: currentParticipant.participantCin,
        participantPhone: currentParticipant.participantPhone,
        participantEmail: currentParticipant.participantEmail,
      });
      this.participantsFormHolder[formIndex].get('participantFirstName').disable()
      this.participantsFormHolder[formIndex].get('participantLastName').disable()
      this.participantsFormHolder[formIndex].get('participantPhone').disable()
      this.participantsFormHolder[formIndex].get('participantEmail').disable()
    } else {
      this.participantsFormHolder[formIndex].get('participantFirstName').reset({ value: '', disabled: false });
      this.participantsFormHolder[formIndex].get('participantLastName').reset({ value: '', disabled: false });
      this.participantsFormHolder[formIndex].get('participantPhone').reset({ value: '', disabled: false });
      this.participantsFormHolder[formIndex].get('participantEmail').reset({ value: '', disabled: false });
    }
  }

  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }
}