import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-bike-sidebar',
  templateUrl: './bike-sidebar.component.html',
  styleUrls: ['./bike-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BikeSidebarComponent implements OnInit {

  apiUrl = environment.apiUrl

  @Input() bikes;
  constructor(private _coreSidebarService: CoreSidebarService) { }

  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  ngOnInit(): void {
  }

}
