import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { environment } from 'environments/environment';

import { ParameterService } from 'app/Service/parameter/parameter.service';
import { OrderService } from 'app/Service/order/order.service';
import { UserService } from 'app/Service/user/user.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PreviewComponent implements OnInit {
  apiUrl = environment.apiUrl

  public loadedData = false
  today = this.datepipe.transform(new Date(), 'dd/MM/YYYY');
  public invoiceParameters
  public admin
  public order
  public participants

  public qrCode

  constructor(
    private _coreConfigService: CoreConfigService,
    private parameterService: ParameterService,
    private userService : UserService,
    private router: ActivatedRoute,
    private route: Router,
    private orderService: OrderService,
    public datepipe: DatePipe) {
    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }
  
  checkColspanForAccessories() {
    if (this.order.discount && this.order.event) {
      return 4
    } else if (!this.order.discount && !this.order.event) {
      return 4
    } else if (!this.order.discount && this.order.event) {
      return 3
    } else if (this.order.discount && !this.order.event) {
      return 5
    }
  }
  checkColspanForCircuit() {
    if (this.order.discount && this.order.event) {
      return 3
    } else if (!this.order.discount && !this.order.event) {
      return 3
    } else if (!this.order.discount && this.order.event) {
      return 2
    } else if (this.order.discount && !this.order.event) {
      return 4
    }
  }

  getOrderData(token) {
    this.orderService.getOrderByToken(token)
      .subscribe({
        next: (data) => {
          this.order = data;
          this.participants = this.order.join
          this.loadedData = true
        },
        error: (e) => console.error(e)
      });
  }

  getQrCode(url) {
    this.orderService.getQrCode(url)
      .subscribe({
        next: (data) => {
          this.qrCode = data['simple']
        },
        error: (e) => {
          console.error(e)
        }
      });
  }
  getAdmin(){
    this.userService.getAdmin()
    .subscribe({
      next: (data) => {
        this.admin = data
      },
      error: (e) => console.error(e)
    });
  }
  getInvoiceParameters() {
    this.parameterService.getInvoiceParammeter()
      .subscribe({
        next: (data) => {
          this.invoiceParameters = data
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.getInvoiceParameters()
    this.getAdmin()
    this.getQrCode(window.location.href)

    let token = this.router.snapshot.paramMap.get('id');
    this.getOrderData(token)
  }

}
