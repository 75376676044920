import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { environment } from 'environments/environment';

import { repeaterAnimation } from 'app/main/forms/form-repeater/form-repeater.animation';

import { AuthenticationService } from 'app/auth/service';
import { OrderService } from 'app/Service/order/order.service';
import { BikeService } from 'app/Service/bike/bike.service';
import { AccessoryService } from 'app/Service/accessory/accessory.service';
import { ParameterService } from 'app/Service/parameter/parameter.service';
import { DiscountService } from 'app/Service/discount/discount.service';
import { ParticipantService } from 'app/Service/participant/participant.service';
import { OrderParticipantBikeService } from 'app/Service/order-participant-bike/order-participant-bike.service';
import { CoreConfigService } from '@core/services/config.service';
import { AgencyService } from 'app/Service/agency/agency.service';
import { AlertService } from 'app/Service/alert/alert.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CircuitService } from 'app/Service/circuit/circuit.service';
import { OrderCircuitService } from 'app/Service/order-circuit/order-circuit.service';

@Component({
  selector: 'app-order-circuit',
  templateUrl: './order-circuit.component.html',
  styleUrls: ['./order-circuit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderCircuitComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  apiUrl = environment.apiUrl

  public min_start = this.datepipe.transform(new Date, 'yyyy-MM-dd HH:mm')
  public min_end = ''
  public max_end = ''
  disableEnd = false
  loadedData = false

  public responsibleIndex = 0
  public responsible = null
  public circuit
  public defaultAgency
  public categoriesTariff
  public oldParticipants
  public bikes
  public circuits
  public checkedCircuit = 18
  public accessories
  public discounts
  public promoDiscounts
  public groupDiscounts

  public chosenBikes = []
  public chosenBikesCategories
  public bikesToOrder

  public totalPrice: number = 0
  public groupPrice = 0
  public discountedPrice = 0

  public circuit_text
  public cuationVelo
  public cautionAccessoire

  participantsFormHolder = [this._formBuilder.group({
    participantId: new FormControl(''),
    participantFirstName: new FormControl(''), participantLastName: new FormControl(''),
    participantCin: new FormControl(''), participantPhone: new FormControl(''),
    participantEmail: new FormControl('', [Validators.email]),
    participantBike: new FormControl(''), bikePrice: '',
    isResponsible: new FormControl(true)
  })];

  public orderForm: FormGroup;
  public orderSubmitted = false
  public loadingSubmitOrder = false

  public step = 1;

  public createdOrder

  constructor(
    private authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private agencyService: AgencyService,
    private participantService: ParticipantService,
    private bikeService: BikeService,
    private circuitService: CircuitService,
    private accessoryService: AccessoryService,
    private discountService: DiscountService,
    private parameterService: ParameterService,
    private orderService: OrderService,
    private orderParticipantBike: OrderParticipantBikeService,
    private orderCircuitService: OrderCircuitService,
    private _formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private _router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) {
    if (!this._router.routerState.snapshot.url.includes('component')) {
      // Configure the layout
      this._coreConfigService.config = {
        layout: {
          navbar: {
            hidden: true
          },
          menu: {
            hidden: true
          },
          footer: {
            hidden: true
          },
          customizer: false,
          enableLocalStorage: false
        }
      };
    }
  }

  public a = 0; public b = 4;
  slide(where) {
    if (this.bikes.length >= this.b) {
      if (where === 'top') {
        this.a--
        this.b--
      } else {
        this.a++
        this.b++
      }
    }
  }

  // convenience getter for easy access to order form fields
  get o() {
    return this.orderForm.controls;
  }

  createOrderCircuit() {
    this.orderCircuitService.create(this.createdOrder['id'], this.circuit.id, this.orderForm.get('start').value, this.orderForm.get('end').value)
      .subscribe({
        next: (data) => {
          this.loadingSubmitOrder = false
          if (this.currentUser && this.currentUser.role === 'agency') {
            this._router.navigate([`/component/agency/circuit/detail/${this.circuit.id}`]);
          } else {
            this._router.navigate([`/success/${this.createdOrder['token']}`]);
          }
        },
        error: (e) => {
          this.loadingSubmitOrder = false
          console.error(e)
        }
      });
  }
  createOrderParticipantBike() {
    for (let p = 0; p < this.participantsFormHolder.length; p++) {
      this.orderParticipantBike.create(this.createdOrder['id'],
        this.participantsFormHolder[p].get('participantId').value,
        this.participantsFormHolder[p].get('participantBike').value['id'],
        this.participantsFormHolder[p]['bikePrice'])
        .subscribe({
          next: (data) => {
            if (p + 1 == this.participantsFormHolder.length) {
              this.createOrderCircuit()
            }
          },
          error: (e) => {
            this.loadingSubmitOrder = false
            console.error(e)
          }
        });
    }
  }
  submitOrder() {
    let price = this.totalPrice
    if (this.groupPrice != 0) {
      price = this.groupPrice
    }
    let discount = ''
    if (this.o.discount.touched && this.o.discount.value != '') {
      discount = this.o.discount.value.id
      price = this.discountedPrice
    }
    this.responsible = this.participantsFormHolder.find(item => item.get('isResponsible').value == true)
    let user = null
    if (this.currentUser) {
      user = this.currentUser.id
    }
    this.chosenBikes = this.participantsFormHolder.filter(item => item.get('participantBike').value != null && item.get('participantBike').value != '')
    if (this.chosenBikes.length > 0) {
      this.chosenBikes = this.chosenBikes.map(item => item.get('participantBike').value['id'])
    }
    this.orderService.create(user, this.defaultAgency.id, null, discount, this.responsible.get('participantId').value, this.participantsFormHolder[0].get('participantId').value, this.o.start.value, this.o.end.value, this.o.comment.value, price, this.o.agent_price.value, this.o.accessory.value, this.chosenBikes, null, this.circuit.id, null)
      .subscribe({
        next: (data) => {
          this.createdOrder = data
          this.createOrderParticipantBike()
        },
        error: (e) => {
          this.loadingSubmitOrder = false
          console.error(e)
        }
      });
  }
  createParticipants(newOnes) {
    for (let p = 0; p < newOnes.length; p++) {
      this.participantService.create(newOnes[p].get('participantCin').value,
        newOnes[p].get('participantFirstName').value, newOnes[p].get('participantLastName').value,
        newOnes[p].get('participantPhone').value, newOnes[p].get('participantEmail').value)
        .subscribe({
          next: (participant_id) => {
            newOnes[p].get('participantId').setValue(participant_id)
            if (p + 1 == newOnes.length) {
              this.submitOrder()
            }
          },
          error: (e) => {
            this.loadingSubmitOrder = false
            console.error(e)
          }
        });
    }
  }

  submitParticipants() {
    if (this.checkParticipantsForm() == true) {
      this.alertService.errorAlert(
        'Vous devez indiquer la cin, le prénom, le nom et le numéro de téléphone de chaque participant',
        'veuillez entrer des données appropriées');
    } else {
      let participantsCin = this.participantsFormHolder.map(item => item.get('participantCin').value)
      if (participantsCin.some((val, i) => participantsCin.indexOf(val) !== i)) {
        this.alertService.errorAlert('Vous avez des participants en double', 'Veuillez vérifier');
      } else {
        this.loadingSubmitOrder = true
        let newOnes = this.participantsFormHolder.filter(f => f.get('participantId').value === '')
        if (newOnes.length != 0) {
          this.createParticipants(newOnes)
        } else {
          this.submitOrder()
        }
      }
    }
  }

  clearDiscount() {
    this.discountedPrice = 0
    this.orderForm.get("agent_price").setValue(this.totalPrice.toFixed(2));
  }
  handleDiscount(e) {
    if (e && e.percentage != null) {
      this.discountedPrice = this.totalPrice - ((e.percentage / 100) * this.totalPrice)
      this.orderForm.get("agent_price").setValue(this.discountedPrice.toFixed(2));
    }
  }
  clearBike(form) {
    form.get('participantBike').setValue('')
    this.calculatePrice()
  }

  next() {
    switch (this.step) {
      case 1: {
        this.orderSubmitted = true
        if (this.orderForm.get('start').valid && this.orderForm.get('end').valid) {
          this.getBikes()
          this.getDiscounts()
        } else {
          return;
        }
        this.orderSubmitted = false
        break;
      }
      case 2: {
        this.getAccessories()
        this.getBikeDepositParameter()
        this.getAccessoryDepositParameter()
        break;
      }
    }
    this.step++
  }

  previous() {
    this.orderSubmitted = false
    this.orderForm.reset()
    this.step--
  }

  getEnd() {
    if (this.o.start.value) {
      let end = new Date(this.o.start.value)
      end.setMinutes(end.getMinutes() + this.circuit.duration)
      this.orderForm.get('end').setValue(this.datepipe.transform(end, 'yyyy-MM-dd HH:mm'))
    }
  }

  handleChosenStart() {
    if (this.orderForm.get('start').valid) {
      this.getEnd()
    }
  }

  // get needed data
  getCircuitParameter() {
    this.parameterService.getSpecificParameter('circuit_text')
      .subscribe({
        next: (data) => {
          this.circuit_text = data[0]
        },
        error: (e) => console.error(e)
      });
  }
  getAccessoryDepositParameter() {
    this.parameterService.getSpecificParameter('accessory_deposit')
      .subscribe({
        next: (data) => {
          this.cautionAccessoire = data[0]
        },
        error: (e) => console.error(e)
      });
  }
  getBikeDepositParameter() {
    this.parameterService.getSpecificParameter('bike_deposit')
      .subscribe({
        next: (data) => {
          this.cuationVelo = data[0];
        },
        error: (e) => console.error(e)
      });
  }
  getDiscounts() {
    this.discountService.getAll()
      .subscribe({
        next: (data) => {
          this.discounts = data
          this.promoDiscounts = this.discounts.filter((obj) => { return obj.statut == true && obj.type === 'promotion' })
          this.groupDiscounts = this.discounts.filter((obj) => { return obj.statut == true && obj.type === 'circuit groupe' })
        },
        error: (e) => console.error(e)
      });
  }
  getAccessories(): void {
    if (this.currentUser && this.currentUser.role === 'agency') {
      this.accessoryService.getFreeAccessories(this.currentUser.id, this.o.start.value, this.o.end.value)
        .subscribe({
          next: (data) => {
            this.accessories = data;
          },
          error: (e) => console.error(e)
        });
    }
  }
  getBikes(): void {
    this.loadedData = false
    let agency = null
    if (this.currentUser && this.currentUser.role === 'agency') {
      agency = this.currentUser.id
    } else {
      agency = this.defaultAgency.id
    }
    this.bikeService.getFreeBikes(agency, this.o.start.value, this.o.end.value)
      .subscribe({
        next: (data) => {
          this.bikes = data;
          this.loadedData = true
          this.toggleSidebar('bike-sidebar')
        },
        error: (e) => console.error(e)
      });
  }
  getOldParticipants(): void {
    this.participantService.getAll()
      .subscribe({
        next: (data) => {
          this.oldParticipants = data;
        },
        error: (e) => console.error(e)
      });
  }
  retrieveCircuitData() {
    this.circuitService.getCircuitByAlias(this.route.snapshot.paramMap.get('id'))
      .subscribe({
        next: (data) => {
          this.circuit = data;
        },
        error: (e) => console.error(e)
      });
  }
  retrieveDefaultAgency() {
    this.agencyService.getAll()
      .subscribe({
        next: (data) => {
          this.defaultAgency = data[0];
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.retrieveDefaultAgency()
    this.retrieveCircuitData()
    if (this.currentUser && this.currentUser.role === 'agency') {
      this.getOldParticipants()
    }

    //order
    this.orderForm = this._formBuilder.group({
      agency: [''],
      day: ['', [Validators.required]],
      start: ['', [Validators.required]],
      end: ['', [Validators.required]],
      comment: [''],
      accessory: [''],
      circuit: [''],
      responsible: ['', [Validators.required]],
      agent_price: [''],
      discount: [''],
      promoCode: [''],
    });
  }

  //participants 
  addParticipant() {
    let newForm = {
      participantId: new FormControl(''),
      participantFirstName: new FormControl(''), participantLastName: new FormControl(''),
      participantCin: new FormControl(''), participantPhone: new FormControl(''),
      participantEmail: new FormControl(''),
      participantBike: new FormControl(''), bikePrice: '',
      isResponsible: new FormControl(false)
    }
    this.participantsFormHolder.push(this._formBuilder.group(newForm))
  }
  deleteParticipant(toDelete) {
    if (toDelete.get('isResponsible').value == true) {
      this.responsibleIndex = 0
      this.participantsFormHolder[0].get('isResponsible').setValue(true)
    }
    let aux: any[] = [];
    for (let form of this.participantsFormHolder) {
      if (toDelete !== form) {
        aux.push(form);
      }
    }
    this.participantsFormHolder = aux;
    this.calculatePrice()
  }
  setIsResonsible(index) {
    this.responsibleIndex = index
    this.participantsFormHolder.forEach(function (item) {
      item.get('isResponsible').setValue(false)
    });
    this.participantsFormHolder[index].get('isResponsible').setValue(true)
  }
  handleParticipantCin() {
    this.calculatePrice()
  }
  calculatePrice() {
    this.totalPrice = 0; this.groupPrice = 0;
    this.chosenBikes = this.participantsFormHolder.filter(item => item.get('participantBike').value && item.get('participantBike').value != '').map(item => item.get('participantBike').value)
    this.totalPrice = ((this.participantsFormHolder.length - this.chosenBikes.map(item => item != null).length) * this.circuit.priceWithoutBike) + this.chosenBikes.length * this.circuit.priceWithBike

    let groupDiscount = this.groupDiscounts.find(item => parseInt(item.minimum) <= this.participantsFormHolder.length && parseInt(item.maximum) >= this.participantsFormHolder.length)
    if (groupDiscount) {
      this.groupPrice = this.totalPrice - ((groupDiscount.percentage / 100) * this.totalPrice)
    }
    this.orderForm.get("agent_price").setValue(this.totalPrice.toFixed(2));
  }
  checkParticipantsForm() {
    let response = 0
    this.participantsFormHolder.forEach(element => {
      if (element.get('participantId').value != '') {
        response++
      } else {
        if (element.get('participantFirstName').value != '' && element.get('participantFirstName').value != null &&
          element.get('participantLastName').value != '' && element.get('participantLastName').value != null &&
          element.get('participantCin').value != '' && element.get('participantCin').value != null &&
          element.get('participantPhone').value != '' && element.get('participantPhone').value != null && element.get('participantPhone').valid
          && element.get('participantEmail').valid) {
          response++
        }
      }
    });
    if (response == this.participantsFormHolder.length) {
      return false
    } else {
      return true
    }
  }

  getParticipant(event, formIndex, attribut) {
    let currentParticipant = this.oldParticipants.find((obj) => {
      return obj[attribut] == event.target.value
    })
    if (currentParticipant) {
      this.participantsFormHolder[formIndex].patchValue({
        participantId: currentParticipant.participantId,
        participantFirstName: currentParticipant.participantFirstName,
        participantLastName: currentParticipant.participantLastName,
        participantCin: currentParticipant.participantCin,
        participantPhone: currentParticipant.participantPhone,
        participantEmail: currentParticipant.participantEmail,
      });
      this.participantsFormHolder[formIndex].get('participantFirstName').disable()
      this.participantsFormHolder[formIndex].get('participantLastName').disable()
      this.participantsFormHolder[formIndex].get('participantPhone').disable()
      this.participantsFormHolder[formIndex].get('participantEmail').disable()
    } else {
      this.participantsFormHolder[formIndex].get('participantFirstName').reset({ value: '', disabled: false });
      this.participantsFormHolder[formIndex].get('participantLastName').reset({ value: '', disabled: false });
      this.participantsFormHolder[formIndex].get('participantPhone').reset({ value: '', disabled: false });
      this.participantsFormHolder[formIndex].get('participantEmail').reset({ value: '', disabled: false });
    }
  }

  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

}
