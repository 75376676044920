import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderParticipantBikeService {

  private _refreshOrderParticipantBike = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshOrderParticipantBike() {
    return this._refreshOrderParticipantBike;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/orderParticipantBike`);
  }

  create(book, participant, bike, bike_price) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/create/orderParticipantBike`, { book, participant, bike, bike_price })
      .pipe(
        tap(() => {
          this._refreshOrderParticipantBike.next()
        })
      );
  }

  update(id, book, participant, bike, bike_price) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/update/orderParticipantBike/${id}`, { book, participant, bike, bike_price })
      .pipe(
        tap(() => {
          this._refreshOrderParticipantBike.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/orderParticipantBike/${id}`)
      .pipe(
        tap(() => {
          this._refreshOrderParticipantBike.next()
        })
      );
  }

}
