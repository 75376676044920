import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private _refreshOrder = new Subject<void>();

  /**
   * Constructor  
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshOrder() {
    return this._refreshOrder;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/order`);
  }

  getOrdersForCalendar() {
    return this._httpClient.get(`${environment.apiUrl}/api/calendar/order`);
  }
  getOrdersForCalendarByAgency(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/calendar/order_by_agency/${id}`);
  }

  getOrder(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/order/${id}`);
  }
  getByAgency(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/order_by_agency/${id}`);
  }
  getByRespncibleAndStartAndEnd(responsibleCin, start, end) {
    return this._httpClient.post(`${environment.apiUrl}/api/order_by_responsible_start_end`, { responsibleCin, start, end });
  }
  getByMonthAndYear(month, year) {
    return this._httpClient.post(`${environment.apiUrl}/api/order_by_month_and_year`, { month, year });
  }

  getOrderByToken(token) {
    return this._httpClient.get(`${environment.apiUrl}/api/orderByToken/${token}`);
  }

  getQrCode(url) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/create/qrCode`, { url })
  }

  createOrderHistory(book, agency, comment, statut) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/create/order_history`, { book, agency, comment, statut })
      .pipe(
        tap(() => {
          this._refreshOrder.next()
        })
      );
  }

  create(user, agency, period, discount, responsible, participant, start, end, comment, price, agent_price, accessory, bikes, event, circuit, promoCode) {
    let statut = null; let payement = false
    return this._httpClient
      .post(`${environment.apiUrl}/api/create/order`, { user, agency, period, discount, responsible, start, end, comment, price, agent_price, statut, payement, accessory, bikes, event, circuit, promoCode })
      .pipe(
        tap(() => {
          this._refreshOrder.next()
        })
      );
  }

  updateStatut(id, statut) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/statut/order/${id}`, { statut })
      .pipe(
        tap(() => {
          this._refreshOrder.next()
        })
      );
  }

  updatePayement(id, payement) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/payement/order/${id}`, { payement })
      .pipe(
        tap(() => {
          this._refreshOrder.next()
        })
      );
  }

  update(id, discount, agent_price, price, responsible, accessory) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/update/order/${id}`, { discount, agent_price, price, responsible, accessory })
      .pipe(
        tap(() => {
          this._refreshOrder.next()
        })
      );
  }

  extendTestEnd(order) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/extend_test_end/order/${order}`, {})
      .pipe(
        tap(() => {
          this._refreshOrder.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/order/${id}`)
      .pipe(
        tap(() => {
          this._refreshOrder.next()
        })
      );
  }

}
