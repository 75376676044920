import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { DatePickerI18nModule } from 'app/main/forms/form-elements/date-time-picker/date-picker-i18n/date-picker-i18n.module';
import { CsvModule } from '@ctrl/ngx-csv';

const routes = [
  {
    path: 'agency',
    loadChildren: () => import('./Agency/agency.module').then(m => m.AgencyModule)
  },
  {
    path: 'customer',
    loadChildren: () => import('./Customer/customer.module').then(m => m.CustomerModule)
  }
];

@NgModule({
  declarations: [
  ],
  imports: [
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CoreCommonModule,
    ContentHeaderModule,
    NgxDatatableModule,
    CsvModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    DatePickerI18nModule,
    SweetAlert2Module.forRoot(),
  ],
  providers: [DatePipe],
  exports: []
})
export class ComponentModule { }