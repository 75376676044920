import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TariffService {

  private _refreshTariff = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshTariff() {
    return this._refreshTariff;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/tariff`);
  }

  getTariffsByPeriod(period, start, end) {
    return this._httpClient.post(`${environment.apiUrl}/api/categoriesTariff`, { period, start, end });
  }

  getByUnitAndValue(unit, value) {
    return this._httpClient.post(`${environment.apiUrl}/api/tariff_by_unit_and_value`, { unit, value });
  }

  getTariffsAccordingToPeriodAndCategory() {
    return this._httpClient.get(`${environment.apiUrl}/api/tariff_according_period_category`);
  }

  create(title, price, minimum, maximum, unit) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/create/tariff`, { title, price, minimum, maximum, unit })
      .pipe(
        tap(() => {
          this._refreshTariff.next()
        })
      );
  }

  update(id, title, price, minimum, maximum, unit) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/update/tariff/${id}`, { title, price, minimum, maximum, unit })
      .pipe(
        tap(() => {
          this._refreshTariff.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/tariff/${id}`)
      .pipe(
        tap(() => {
          this._refreshTariff.next()
        })
      );
  }

}
