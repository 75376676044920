<section class="faq-contact">
    <div class="row mt-5 pt-75">
        <div class="col-sm-6">
            <div class="card text-center faq-contact-card shadow-none py-1">
                <div class="card-body">
                    <div class="avatar avatar-tag bg-light-primary mb-2 mx-auto">
                        <i data-feather="phone-call" class="font-medium-3"></i>
                    </div>
                    <h4 *ngIf="agency_phone">+ (216) {{agency_phone.value}}</h4>
                    <span class="text-body">Nous sommes toujours heureux de vous répondre!</span>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card text-center faq-contact-card shadow-none py-1">
                <div class="card-body">
                    <div class="avatar avatar-tag bg-light-primary mb-2 mx-auto">
                        <i data-feather="mail" class="font-medium-3"></i>
                    </div>
                    <h4 *ngIf="agency_email">{{agency_email.value}}</h4>
                    <span class="text-body">Le meilleur moyen d'obtenir une réponse rapide!</span>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- footer -->
<app-footer></app-footer>
<!-- / footer -->