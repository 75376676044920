import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PeriodService {

  private _refreshPeriod = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshPeriod() {
    return this._refreshPeriod;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/period`);
  }

  create(type, value) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/create/period`, { type, value } )
      .pipe(
        tap(() => {
          this._refreshPeriod.next()
        })
      );
  }

  update(id, type, value) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/update/period/${id}`, { type, value })
      .pipe(
        tap(() => {
          this._refreshPeriod.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/period/${id}`)
      .pipe(
        tap(() => {
          this._refreshPeriod.next()
        })
      );
  }

}
