<div class="text-center" *ngIf="!loadedData">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div *ngIf="loadedData">
    <div class="text-right m-2">
        <a class="btn btn-primary print-now" href="javascript:window.print();" rippleEffect>
            Imprimer
        </a>
    </div>
    <div #toDownload class="content-body">
        <section class="invoice-preview-wrapper">
            <div class="row invoice-preview">
                <!-- Invoice -->
                <div class="col-xl-12 col-md-8 col-12 printRef">
                    <div class="card invoice-preview-card">
                        <div class="card-body invoice-padding pb-0">
                            <div class="row mb-5">
                                <div class="col-xl-6">
                                    <div class="logo-wrapper">
                                        <img src="{{apiUrl}}/uploads/{{order.agency_logo}}" height="180">
                                        <h3 class="text-primary invoice-logo">{{order.agency_title}}</h3>
                                    </div>
                                </div>
                                <div class="col-xl-6">
                                    <div class="logo-wrapper  float-right">
                                        <!-- admin logo -->
                                        <img src="{{apiUrl}}/uploads/{{admin.logo}}" height="180">
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="invoiceParameters">
                                <div class="col-xl-5" id="c">
                                    <p class="card-text mb-25"><strong>Adresse: </strong>{{
                                        order.agency_address }}</p>
                                    <p class="card-text mb-25"><strong>E-mail: </strong>{{
                                        order.agency_email }}</p>
                                    <p class="card-text mb-0"><strong>Téléphone: </strong>{{
                                        order.agency_phone }}</p>
                                </div>
                                <img class="col-xl-2" id="b" src="{{qrCode}}">
                                <div class="col-xl-5 text-right" id="a">
                                    <div class="mb-2 mr-2">
                                        <h2 class="text-primary">Contrat
                                            <span class="invoice-number">#{{order.id}}</span>
                                        </h2>
                                        <h4 *ngIf="order.event">Événement:
                                            <span class="invoice-number">{{ order.event.title }}</span>
                                            <h6 class="invoice-date-title">{{ order.event.description }}</h6>
                                        </h4>
                                        <h4> Date de réservation:
                                            <span class="invoice-number">{{ order.created_at }}</span>
                                        </h4>
                                    </div>
                                    <h6 class="invoice-date-title mr-2">Date de début de
                                        {{order.event ? "l'évènement":'réservation'}}: {{ order.start }}
                                    </h6>
                                    <h6 class="invoice-date-title mr-2">Date de fin de
                                        {{ order.event ? "l'évènement":'réservation'}}: {{ order.end }}</h6>
                                </div>
                            </div>
                        </div>
                        <!-- Address And Contact Starts -->
                        <div class="card-body invoice-padding pt-0">
                            <div class="row invoice-spacing">
                                <div class="col-xl-8 p-0">
                                    <h2 class="mb-2">Client:</h2>
                                    <p class="card-text mb-25">
                                        <strong>Nom: </strong>
                                        {{ order.responsible_firstName }} {{ order.responsible_lastName }}
                                    </p>
                                    <p class="card-text mb-25"><strong>Identifiant:</strong>
                                        {{ order.responsible_id }},
                                        <strong>Cin: </strong>
                                        {{ order.responsible_cin }}
                                    </p>
                                    <p class="card-text mb-25">
                                        <strong>Téléphone: </strong>
                                        {{ order.responsible_phone }},
                                        <span *ngIf="order.responsible_email"><strong>E-mail: </strong> {{
                                            order.responsible_email }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Address And Contact Ends  -->

                        <!-- Invoice Description Starts -->
                        <div id="basic-table" *ngIf="participants">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="py-1 col-6">Articles</th>
                                        <th class="py-1">Durée</th>
                                        <th *ngIf="!order.event" class="py-1">Prix unitaire</th>
                                        <th *ngIf="order.discount || order.dealer" class="py-1">Réduction</th>
                                        <th class="py-1">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let participant of participants">
                                        <td class="py-1">
                                            <p *ngIf="participant.bike" class="card-text font-weight-bold mb-25">
                                                Vélo {{ participant.bike.reference }}
                                            </p>
                                            <p *ngIf="participant.bike" class="card-text  mb-25">
                                                {{ participant.bike.kind }}
                                                {{ participant.bike.category_title}}
                                                {{ participant.bike.size }}
                                            </p>
                                            <p class="card-text  mb-25">
                                                {{ participant.participant.participantFirstName}}
                                                {{ participant.participant.participantPhone }}
                                            </p>
                                        </td>
                                        <td class="py-1">
                                            <span class="font-weight-bold" *ngIf="order.period">
                                                {{ order.period.value === '*' ? 'Plusieurs' : order.period.value }}
                                                <span *ngIf="order.period.type == 'hour'">heures</span>
                                                <span *ngIf="order.period.type == 'day'">jours</span> </span>
                                        </td>
                                        <td class="py-1" *ngIf="!order.event">
                                            <div class="font-weight-bold">
                                                <span *ngIf="participants.length === 1">
                                                    {{participant.bike.bike_price}}
                                                </span>
                                                <span *ngIf="participants.length > 1">
                                                    {{(participant.bike.bike_price/order.period.value).toFixed(2)}}
                                                </span>
                                                DT
                                            </div>
                                        </td>
                                        <td class="py-1" *ngIf="order.discount || order.dealer">
                                            <span class="font-weight-bold">{{ order.discount ?
                                                order.discount.percentage+'%':order.dealer.promoCode }}</span>
                                        </td>
                                        <td class="py-1">
                                            <span class="font-weight-bold" *ngIf="!order.event">{{
                                                participant.bike.bike_price }} DT</span>
                                            <div *ngIf="order.event">
                                                <span *ngIf="!participant.bike">
                                                    {{order.event.priceWithoutBike}}
                                                </span>
                                                <span *ngIf="participant.bike">
                                                    {{order.event.priceWithBike}}
                                                </span>
                                                DT
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr *ngIf="order.accessories.length > 0">
                                        <td class="py-1" [attr.colspan]="checkColspanForAccessories()">
                                            <p class="card-text font-weight-bold mb-25">
                                                Accessoires
                                            </p>
                                            <p class="card-text" *ngFor="let accessory of order.accessories">
                                                {{accessory.reference }} - {{ accessory.title }}
                                            </p>
                                        </td>
                                    </tr>
                                    <!-- <tr *ngIf="invoiceParameters">
                                        <td class="py-1" [attr.colspan]="checkColspanForCircuit()">
                                            <p class="card-text font-weight-bold mb-25">
                                                Pièces de rechange
                                            </p>
                                            <p class="card-text">
                                                Caution Vélo(s)<br>
                                                1 Vélo(s) * {{invoiceParameters.bike_deposit}} DT
                                            </p>
                                            <p class="card-text" *ngIf="order.accessories.length > 0">
                                                Caution Accessoire(s)<br>
                                                1 Accessoire(s) * {{invoiceParameters.accessory_deposit}} DT
                                            </p>
                                        </td>
                                        <td>
                                            <p class="card-text font-weight-bold">
                                                {{ (participants.length * invoiceParameters.bike_deposit).toFixed(2) }}
                                                DT</p>
                                            <p class="card-text font-weight-bold" *ngIf="order.accessories.length > 0">
                                                {{ (order.accessories.length *
                                                invoiceParameters.accessory_deposit).toFixed(2) }} DT</p>
                                        </td>
                                    </tr> -->
                                    <tr *ngIf="order.circuit">
                                        <td class="py-1" [attr.colspan]="checkColspanForCircuit()">
                                            <p class="card-text font-weight-bold mb-25">
                                                Circuits guidés
                                            </p>
                                            <p class="card-text">
                                                {{order.circuit.title}}<br>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="card-text font-weight-bold">
                                                {{ order.circuit.price }}
                                                DT</p>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <hr>
                        <div class="card-body invoice-padding pb-0">
                            <div class="row invoice-sales-total-wrapper">
                                <div class="row col-md-9 order-md-1 order-2 mt-md-0 mt-3">
                                    <div class="card bg-transparent border-dark col-3 h-100 mr-2">
                                        <div class="card-body">
                                            <div class="text-center font-weight-bold row">Djerba le: {{ today }}</div>
                                            <div class="text-center font-weight-bold">Loueur</div>
                                            <p class="card-text"></p>
                                        </div>
                                    </div>
                                    <div class="card bg-transparent border-dark col-3 h-100">
                                        <div class="card-body">
                                            <div class="text-center font-weight-bold">Contractant</div>
                                            <p class="card-text"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 d-flex justify-content-end order-md-2 order-1">
                                    <div class="invoice-total-wrapper">
                                        <div class="invoice-total-item" *ngIf="order.discount || order.dealer">
                                            <p class="invoice-total-title">Sous-Total (avant rèduction):</p>
                                            <p class="invoice-total-amount"> <span
                                                    *ngIf="order.discount">{{(order.price/(1-(order.discount.percentage/100))).toFixed(2)}}</span>
                                                <span
                                                    *ngIf="order.dealer">{{(order.price/(1-(order.dealer.percentage/100))).toFixed(2)}}</span>DT
                                            </p>
                                        </div>
                                        <hr class="my-50" *ngIf="order.discount || order.dealer" />
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title">Total:</p>
                                            <p class="invoice-total-amount">{{ order.agent_price }} DT</p>
                                        </div>
                                        <!-- <hr class="my-50" />
                                        <div class="invoice-total-item" *ngIf="invoiceParameters">
                                            <p class="invoice-total-title">CAUTION:</p>
                                            <p class="invoice-total-amount">
                                                {{((participants.length *
                                                invoiceParameters.bike_deposit)+(order.accessories.length *
                                                invoiceParameters.accessory_deposit)).toFixed(2) }} DT
                                            </p>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Invoice Description Ends -->

                        <hr class="invoice-spacing" />

                        <!-- Invoice Note Starts -->
                        <div class="card-body invoice-padding pt-0">
                            <div class="row">
                                <div class="col-12" *ngIf="invoiceParameters">
                                    <div [innerHTML]="invoiceParameters.invoice_description"></div>
                                </div>
                            </div>
                        </div>
                        <!-- Invoice Note Ends -->
                    </div>
                </div>
                <!--/ Invoice -->
            </div>
        </section>
    </div>
</div>