import { ContentHeader } from './../../layout/components/content-header/content-header.component';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';


import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  private _refreshBanner = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshBanner() {
    return this._refreshBanner;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/banner`);
  }

  getBannerBy(param) {
    return this._httpClient.get(`${environment.apiUrl}/api/bannerBy/${param}`);
  }

  create(title, button, link, image, description, banner_type) {
    const formData: FormData = new FormData();
    formData.append('title', title);
    formData.append('button', button);
    formData.append('link', link);
    formData.append('image', image);
    formData.append('description', description);
    formData.append('type', banner_type);

    return this._httpClient
      .post(`${environment.apiUrl}/api/create/banner`, formData)
      .pipe(
        tap(() => {
          this._refreshBanner.next()
        })
      );
  }

  update(id, title, button, link, image, description, banner_type) {
    const formData: FormData = new FormData();
    formData.append('title', title);
    formData.append('button', button);
    formData.append('link', link);
    formData.append('image', image);
    formData.append('description', description);
    formData.append('type', banner_type);

    return this._httpClient
      .post(`${environment.apiUrl}/api/update/banner/${id}`, formData)
      .pipe(
        tap(() => {
          this._refreshBanner.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/banner/${id}`)
      .pipe(
        tap(() => {
          this._refreshBanner.next()
        })
      );
  }

}
