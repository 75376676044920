import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {

  private _refreshParticipant = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshParticipant() {
    return this._refreshParticipant;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/participant`);
  }

  create(cin, first_name, last_name, phone, email) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/create/participant`, { cin, first_name, last_name, phone, email })
      .pipe(
        tap(() => {
          this._refreshParticipant.next()
        })
      );
  }

  update(id, cin, firstName, lastName, phone, email) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/update/participant/${id}`, { cin, firstName, lastName, phone, email })
      .pipe(
        tap(() => {
          this._refreshParticipant.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/participant/${id}`)
      .pipe(
        tap(() => {
          this._refreshParticipant.next()
        })
      );
  }

  sendEmail(participants, subject, content) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/sms/email`, { participants, subject, content })
  }

  sendSms(participants, content) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/sms/send`, { participants, content })
  }

}
