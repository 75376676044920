import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient,
    private _toastrService: ToastrService,
    private _router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is agency
   */
  get isAgency() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Agency;
  }

  /**
   *  Confirms if user is client
   */
  get isCustomer() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Customer;
  }

  login(username: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/api/auth/login`, { username, password })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this._router.navigate(['/']);
            // notify
            this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }

  registerCustomer(first_name, last_name, cin, phone, email, password) {
    return this._http
      .post<any>(`${environment.apiUrl}/api/register/customer`, { first_name, last_name, cin, phone, email, password })
      .pipe(
        map(user => {

        })
      );
  }

  refreshToken(refresh_token) {
    return this._http
      .post<any>(`${environment.apiUrl}/api/token/refresh`, { refresh_token })
  }

  forgetPassword(email) {
    return this._http
      .post<any>(`${environment.apiUrl}/api/forget/password`, { email })
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }
}
