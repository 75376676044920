import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { CoreConfigService } from '@core/services/config.service';
import { ParameterService } from 'app/Service/parameter/parameter.service';
import { OrderService } from 'app/Service/order/order.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderSuccessComponent implements OnInit {

  public parameters
  public order

  constructor(
    private _coreConfigService: CoreConfigService,
    private parameterService: ParameterService,
    private router: ActivatedRoute,
    private orderService: OrderService
  ) {
    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  getOrderData() {
    this.orderService.getOrderByToken(this.router.snapshot.paramMap.get('token'))
      .subscribe({
        next: (data) => {
          this.order = data
        },
        error: (e) => console.error(e)
      });
  }
  getOrderSuccessParameter() {
    this.parameterService.getOrderSuccessParammeter()
      .subscribe({
        next: (data) => {
          this.parameters = data
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.getOrderSuccessParameter()
    this.getOrderData()
  }

}
