import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private _refreshEvent = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshEvent() {
    return this._refreshEvent;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/event`);
  }

  getEvent(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/event/${id}`);
  }

  getEventByAlias(alias) {
    return this._httpClient.get(`${environment.apiUrl}/api/eventByAlias/${alias}`);
  }

  create(agency, title, difficulty, principal_image, multiple_image, banner, priceWithBike, priceWithoutBike, start, end, journey, description, detailed_description) {
    const formData: FormData = new FormData();
    formData.append('agency', agency); formData.append('title', title);
    formData.append('description', description); formData.append('detailed_description', detailed_description);
    formData.append('principal_image', principal_image);
    if (multiple_image) {
      for (let i = 0; i < multiple_image.length; i++) {
        formData.append('multiple_image[]', multiple_image[i]);
      }
    }
    formData.append('banner', banner);
    formData.append('priceWithBike', priceWithBike); formData.append('priceWithoutBike', priceWithoutBike);
    formData.append('start', start); formData.append('end', end);
    formData.append('difficulty', difficulty); formData.append('journey', journey);
    return this._httpClient
      .post(`${environment.apiUrl}/api/create/event`, formData)
      .pipe(
        tap(() => {
          this._refreshEvent.next()
        })
      );
  }

  updateStatut(id, statut) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/statut/event/${id}`, { statut })
      .pipe(
        tap(() => {
          this._refreshEvent.next()
        })
      );
  }

  update(id, title, difficulty, principal_image, multiple_image, banner, priceWithBike, priceWithoutBike, start, end, journey, description, detailed_description) {
    const formData: FormData = new FormData();
    formData.append('title', title);
    formData.append('description', description); formData.append('detailed_description', detailed_description);
    formData.append('principal_image', principal_image);
    if (multiple_image) {
      for (let i = 0; i < multiple_image.length; i++) {
        formData.append('multiple_image[]', multiple_image[i]);
      }
    }
    formData.append('banner', banner);
    formData.append('priceWithBike', priceWithBike); formData.append('priceWithoutBike', priceWithoutBike);
    formData.append('start', start); formData.append('end', end);
    formData.append('difficulty', difficulty); formData.append('journey', journey);
    return this._httpClient
      .post(`${environment.apiUrl}/api/update/event/${id}`, formData)
      .pipe(
        tap(() => {
          this._refreshEvent.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/event/${id}`)
      .pipe(
        tap(() => {
          this._refreshEvent.next()
        })
      );
  }

}
