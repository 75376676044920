import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessoryService {

  private _refreshAccessory = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshAccessory() {
    return this._refreshAccessory;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/accessory`);
  }

  getByAgency(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/accessory_by_agency/${id}`);
  }

  getFreeAccessories(agency, start, end) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/get_free_accessories`, { agency, start, end })
      .pipe(
        tap(() => {
          this._refreshAccessory.next()
        })
      );
  }

  create(agency, category, title, reference, price, statut, principal_image, multiple_image) {
    const formData: FormData = new FormData();
    formData.append('agency', agency);
    formData.append('categoryAccessory', category);
    formData.append('title', title);
    formData.append('reference', reference);
    formData.append('price', price);
    formData.append('statut', statut);
    formData.append('principal_image', principal_image);
    if (multiple_image) {
      for (let i = 0; i < multiple_image.length; i++) {
        formData.append('multiple_image[]', multiple_image[i]);
      }
    }

    return this._httpClient
      .post(`${environment.apiUrl}/api/create/accessory`, formData)
      .pipe(
        tap(() => {
          this._refreshAccessory.next()
        })
      );
  }

  update(id, agency, category, title, reference, price, principal_image, multiple_image) {
    const formData: FormData = new FormData();
    formData.append('agency', agency);
    formData.append('categoryAccessory', category);
    formData.append('title', title);
    formData.append('reference', reference);
    formData.append('price', price);
    formData.append('principal_image', principal_image);
    if (multiple_image) {
      for (let i = 0; i < multiple_image.length; i++) {
        formData.append('multiple_image[]', multiple_image[i]);
      }
    }

    return this._httpClient
      .post(`${environment.apiUrl}/api/update/accessory/${id}`, formData)
      .pipe(
        tap(() => {
          this._refreshAccessory.next()
        })
      );
  }

  updateStatut(accessory, statut) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/update_statut/accessory`, { accessory, statut })
      .pipe(
        tap(() => {
          this._refreshAccessory.next()
        })
      );
  }

  createAccessoryHistory(accessory, agency, comment, statut) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/create/accessory_history`, { accessory, agency, comment, statut })
      .pipe(
        tap(() => {
          this._refreshAccessory.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/accessory/${id}`)
      .pipe(
        tap(() => {
          this._refreshAccessory.next()
        })
      );
  }

}
