import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ParameterService } from 'app/Service/parameter/parameter.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit {

  public agency_email
  public agency_phone
  constructor(private parameterService: ParameterService) { }

  getAgencyPhone(){
    this.parameterService.getSpecificParameter('agency_phone')
    .subscribe({
      next: (data) => {
        this.agency_phone = data[0];
      },
      error: (e) => console.error(e)
    });
  }
  getAgencyEmail(){
    this.parameterService.getSpecificParameter('agency_email')
    .subscribe({
      next: (data) => {
        this.agency_email = data[0];
      },
      error: (e) => console.error(e)
    });
  }

  ngOnInit(): void {
    this.getAgencyEmail()
    this.getAgencyPhone()
  }

}
