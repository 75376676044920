import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BikeService {

  private _refreshBike = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshBike() {
    return this._refreshBike;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/bike`);
  }


  getByAgency(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/liste/bike_by_agency/${id}`);
  }

  getBike(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/bike/${id}`);
  }

  getFreeBikes(agency, start, end) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/get_free_bikes`, { agency, start, end })
      .pipe(
        tap(() => {
          this._refreshBike.next()
        })
      );
  }

  create(agency, reference, brand, category, kind, size, principal_image, multiple_image, description, statut) {
    const formData: FormData = new FormData();
    formData.append('agency', agency); formData.append('reference', reference);
    formData.append('brand', brand); formData.append('category', category);
    formData.append('kind', kind); formData.append('size', size);

    formData.append('principal_image', principal_image);
    if (multiple_image != null) {
      for (let i = 0; i < multiple_image.length; i++) {
        formData.append('multiple_image[]', multiple_image[i]);
      }
    }
    formData.append('description', description); formData.append('reference', reference);
    formData.append('statut', statut);

    return this._httpClient
      .post(`${environment.apiUrl}/api/create/bike`, formData)
      .pipe(
        tap(() => {
          this._refreshBike.next()
        })
      );
  }

  update(id, agency, reference, brand, category, kind, size, principal_image, multiple_image, description) {
    const formData: FormData = new FormData();
    formData.append('agency', agency); formData.append('reference', reference);
    formData.append('brand', brand); formData.append('category', category);
    formData.append('kind', kind); formData.append('size', size);

    formData.append('principal_image', principal_image);
    if (multiple_image != null) {
      for (let i = 0; i < multiple_image.length; i++) {
        formData.append('multiple_image[]', multiple_image[i]);
      }
    }
    formData.append('description', description); formData.append('reference', reference);

    return this._httpClient
      .post(`${environment.apiUrl}/api/update/bike/${id}`, formData)
      .pipe(
        tap(() => {
          this._refreshBike.next()
        })
      );
  }

  updateStatut(bike, statut) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/update_statut/bike`, { bike, statut })
      .pipe(
        tap(() => {
          this._refreshBike.next()
        })
      );
  }

  createBikeHistory(bike, agency, comment, statut) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/create/bike_history`, { bike, agency, comment, statut })
      .pipe(
        tap(() => {
          this._refreshBike.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/bike/${id}`)
      .pipe(
        tap(() => {
          this._refreshBike.next()
        })
      );
  }

}
